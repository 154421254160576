import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 18 16",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "stroke-width": "2",
      d: "M11.5 1.438v13.125M1 3.624v8.75C1 13.825 2.343 15 4 15h10c1.657 0 3-1.175 3-2.625v-8.75C17 2.175 15.657 1 14 1H4C2.343 1 1 2.175 1 3.625Z"
    }, null, -1)
  ])))
}
export default { render: render }