import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 12",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 6A6 6 0 1 1 0 6a6 6 0 0 1 12 0m-6 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8" }, null, -1),
    _createElementVNode("path", { d: "M10 12a6 6 0 1 0-4.5-9.97 4 4 0 0 1 2.5.504 4 4 0 1 1 0 6.93 4 4 0 0 1-2.5.505A6 6 0 0 0 10 12" }, null, -1),
    _createElementVNode("path", { d: "M14 12a6 6 0 1 0-4.5-9.97 3.98 3.98 0 0 1 2.5.504 4 4 0 1 1 0 6.93 3.98 3.98 0 0 1-2.5.505A6 6 0 0 0 14 12" }, null, -1)
  ])))
}
export default { render: render }