import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 21 20",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M10.224 18H4.707C3.488 18 2.5 16.977 2.5 15.714V4.286C2.5 3.023 3.488 2 4.707 2h9.931c1.219 0 2.207 1.023 2.207 2.286V6.57m-.448 8.572L18 16.5m-1.052-3.643c0 1.578-1.235 2.857-2.758 2.857-1.524 0-2.759-1.279-2.759-2.857S12.666 10 14.19 10s2.758 1.28 2.758 2.857"
    }, null, -1)
  ])))
}
export default { render: render }