import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "currentColor",
  "stroke-width": "1.5",
  viewBox: "0 0 12 14",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      d: "M10.75 13v-1.5c0-.796-.257-1.559-.714-2.121S8.96 8.5 8.313 8.5H3.436c-.646 0-1.266.316-1.723.879C1.257 9.94 1 10.704 1 11.5V13m7.5-9.375a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
    }, null, -1)
  ])))
}
export default { render: render }