import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "currentColor",
  "stroke-linecap": "round",
  "stroke-width": "2",
  viewBox: "0 0 20 20",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M19 9A9 9 0 1 0 9 19" }, null, -1),
    _createElementVNode("path", { d: "M19 9A2 3 42 0 0 9 19m0 0L19 9" }, null, -1)
  ])))
}
export default { render: render }